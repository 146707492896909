import {
	FETCH_USER_PROFILE,
	FETCH_USER_PROFILE_SUCCESSED,
	FETCH_USER_PROFILE_FAILED,
} from 'core/redux/actions/ActionTypes';

const INIT_STATE = {
	profile: null,
	message: '',
};

const UserProfileReducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_USER_PROFILE:
			return {
				...state,
				message: '',
			};
		case FETCH_USER_PROFILE_SUCCESSED:
			return {
				profile: action.profile,
				message: action.message ?? '',
			};
		case FETCH_USER_PROFILE_FAILED:
			return {
				...state,
				message: action.message ?? '',
			};
		default:
			return state;
	}
};

export default UserProfileReducer;
