import {
	FETCH_JOB_LIST,
	FETCH_COUNT_JOB,
	SET_IS_DISABLE_SWIPE,
	FETCH_MOVE_EVENT,
	FETCH_RESIZE_EVENT,
	STOP_FETCH_MOVE_EVENT,
	STOP_FETCH_RESIZE_EVENT,
	REFRESH_ALL_CALENDAR,
} from 'core/redux/actions/ActionTypes';

export const fetchJobList = (params, token, onSuccess = () => {}, onFailed = () => {}) => {
	return {
		type: FETCH_JOB_LIST,
		params,
		token,
		onSuccess,
		onFailed,
	};
};

export const fetchCountJob = (params, token, onSuccess = () => {}, onFailed = () => {}) => {
	return {
		type: FETCH_COUNT_JOB,
		params,
		token,
		onSuccess,
		onFailed,
	};
};

export const setIsDisableSwipe = isDisable => {
	return {
		type: SET_IS_DISABLE_SWIPE,
		isDisable,
	};
};

export const fetchDragEvent = (id, typeEvent, params, onSuccess = () => {}, onFailed = () => {}) => {
	return {
		type: FETCH_MOVE_EVENT,
		id,
		typeEvent,
		params,
		onSuccess,
		onFailed,
	};
};

export const stopFetchDragEvent = () => {
	return {
		type: STOP_FETCH_MOVE_EVENT,
	};
};

export const fetchResizeEvent = (id, typeEvent, params, onSuccess = () => {}, onFailed = () => {}) => {
	return {
		type: FETCH_RESIZE_EVENT,
		id,
		typeEvent,
		params,
		onSuccess,
		onFailed,
	};
};

export const stopFetchResizeEvent = () => {
	return {
		type: STOP_FETCH_RESIZE_EVENT,
	};
};

export const refreshAllCalendar = () => {
	return {
		type: REFRESH_ALL_CALENDAR,
	};
};
