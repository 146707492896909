import { all } from 'redux-saga/effects';

import { watchUserProfile } from 'core/redux/sagas/profile/UserProfileSagas';
import {
	watchFetchJobList,
	watchFetchCountJob,
	watchFetchMoveEvent,
	watchFetchResizeEvent,
} from 'core/redux/sagas/calendar/CalendarSagas';

export default function* rootSaga() {
	yield all([
		watchUserProfile(),
		watchFetchJobList(),
		watchFetchCountJob(),
		watchFetchMoveEvent(),
		watchFetchResizeEvent(),
	]);
}
