import { call, takeLeading } from 'redux-saga/effects';

import FetchAPI from 'core/utils/FetchAPI';

import { FETCH_USER_PROFILE } from 'core/redux/actions/ActionTypes';
import { LOGIN_INFO } from 'app/constants/Api';

function* fetchUserProfile(action) {
	try {
		const results = yield call(FetchAPI.getFetch, LOGIN_INFO, {}, action.token, { 'api-version': '1.0' });
		if (results.success) {
			action.resolve(results);
		} else {
			action.reject();
		}
	} catch (error) {
		action.reject();
	}
}

export function* watchUserProfile() {
	yield takeLeading(FETCH_USER_PROFILE, fetchUserProfile);
}
