import React from 'react';
import SocketIO from 'socket.io-client';

import { REALTIME_ENDPOINT } from 'configs/environments';
import { OPTIONS, SOCKET_NAMESPACES } from 'app/constants/Realtime';

const socket = SocketIO(REALTIME_ENDPOINT + SOCKET_NAMESPACES.JOB, OPTIONS);

const JobSocketContext = React.createContext();

export { socket, JobSocketContext };
