import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import routers from './router';

const App = () => {
	return (
		<Router basename='/'>
			{routers.map(({ path, component, exact }) => {
				return (
					<Route
						key={path}
						exact={exact === true ? true : false}
						path={path}
						component={component}
					/>
				);
			})}
		</Router>
	);
};

export default App;
