import Calendar from 'app/modules/calendar/index';

const routers = [
	{
		path: '/',
		component: Calendar,
		exact: true,
	},
];
export default routers;
