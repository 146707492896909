import dayjs from 'dayjs';
import { TIME_GRID_DAY, DAY_GRID_MONTH, TIME_GRID_WEEK, TIME_GRID_4_DAYS, TIME_GRID_3_DAYS } from 'app/constants/App';

const today = dayjs().format('YYYY-MM-DD');

// get list date
const getInitDate = (mode, date) => {
	switch (mode) {
		case TIME_GRID_WEEK: {
			const pastWeek = Array.from({ length: 10 }).map((el, index) => ({
				startDate: dayjs(date)
					.subtract(1 * (index + 1), 'week')
					.format('YYYY-MM-DD'),
			}));
			return [
				...pastWeek.reverse(),
				{ startDate: date },
				...Array.from({ length: 10 }).map((el, index) => ({
					startDate: dayjs(date)
						.add(1 * (index + 1), 'week')
						.format('YYYY-MM-DD'),
				})),
			];
		}

		case DAY_GRID_MONTH: {
			const pastMoth = Array.from({ length: 10 }).map((el, index) => ({
				startDate: dayjs(date)
					.subtract(1 * (index + 1), 'month')
					.format('YYYY-MM-DD'),
			}));
			return [
				...pastMoth.reverse(),
				{ startDate: date },
				...Array.from({ length: 10 }).map((el, index) => ({
					startDate: dayjs(date)
						.add(1 * (index + 1), 'month')
						.format('YYYY-MM-DD'),
				})),
			];
		}

		case TIME_GRID_3_DAYS: {
			const past3Day = Array.from({ length: 10 }).map((el, index) => ({
				startDate: dayjs(date)
					.subtract(3 * (index + 1), 'day')
					.format('YYYY-MM-DD'),
			}));
			return [
				...past3Day.reverse(),
				{ startDate: date },
				...Array.from({ length: 10 }).map((el, index) => ({
					startDate: dayjs(date)
						.add(3 * (index + 1), 'day')
						.format('YYYY-MM-DD'),
				})),
			];
		}

		case TIME_GRID_4_DAYS: {
			const past4Day = Array.from({ length: 10 }).map((el, index) => ({
				startDate: dayjs(date)
					.subtract(4 * (index + 1), 'day')
					.format('YYYY-MM-DD'),
			}));
			return [
				...past4Day.reverse(),
				{ startDate: date },
				...Array.from({ length: 10 }).map((el, index) => ({
					startDate: dayjs(date)
						.add(4 * (index + 1), 'day')
						.format('YYYY-MM-DD'),
				})),
			];
		}

		default: {
			const pastDefaultDay = Array.from({ length: 10 }).map((el, index) => ({
				startDate: dayjs(date)
					.subtract(1 * (index + 1), 'day')
					.format('YYYY-MM-DD'),
			}));
			return [
				...pastDefaultDay.reverse(),
				{ startDate: date },
				...Array.from({ length: 10 }).map((el, index) => ({
					startDate: dayjs(date)
						.add(1 * (index + 1), 'day')
						.format('YYYY-MM-DD'),
				})),
			];
		}
	}
};

// get day obj
const getDayObj = (date = today) => {
	return {
		dateString: date,
		weekDay: dayjs(date).format('ddd'),
	};
};

const getDaysArray = (start, end) => {
	if (!start || !end) return [];

	let days = [];
	let day = start;

	while (day <= end) {
		const obj = getDayObj(dayjs(day).format('YYYY-MM-DD'));
		days.push(obj);

		// idx
		day = day.clone().add(1, 'd');
	}

	return days;
};

// get 1 week
// each day is day obj
const getWeek = (date = today) => {
	const startOfWeek = dayjs(date).startOf('week');
	const endOfWeek = dayjs(date).endOf('week');

	return getDaysArray(startOfWeek, endOfWeek);
};

// get 3 weeks array: for week view                   // 0...6 | 7(start)...13 | 14...20
const get3Weeks = (date = today, isShowWeekends = true) => {
	const startDate = dayjs(date).startOf('week');
	const endDate = dayjs(date).endOf('week');

	let result = getDaysArray(startDate, endDate);
	if (isShowWeekends == 'false') result = result?.filter(o => !['Sat', 'Sun'].includes(o?.weekDay));

	return result;
};

// get 3 days rolling: 9 days
// 4th day (index = 3, page 2) is today
const get3DaysRolling = (date = today) => {
	const startDate = dayjs(date); // 0 1 2 | 3(today) 4 5 | 6 7 8
	const endDate = dayjs(date).add(2, 'day');

	return getDaysArray(startDate, endDate);
};

// get 4 days rolling: 12 days
// 4th day (index = 4, page 2) is today
const get4DaysRolling = (date = today) => {
	const startDate = dayjs(date); // 0...3 | 4(today)...7 | 8...11
	const endDate = dayjs(date).add(3, 'day');

	return getDaysArray(startDate, endDate);
};

const getDateRange = (mode, date = today) => {
	let start, end;

	switch (mode) {
		case TIME_GRID_DAY:
			start = dayjs(date).format('YYYY-MM-DD');
			end = start;
			break;

		case TIME_GRID_WEEK:
			start = dayjs(date).startOf('week').format('YYYY-MM-DD');
			end = dayjs(date).endOf('week').format('YYYY-MM-DD');
			break;

		case DAY_GRID_MONTH:
			start = dayjs(date).startOf('month').startOf('week').format('YYYY-MM-DD');
			end = dayjs(start, 'YYYY-MM-DD').add(5, 'week').endOf('week').format('YYYY-MM-DD');
			break;

		case TIME_GRID_3_DAYS:
			start = date;
			end = dayjs(date).add(2, 'day').format('YYYY-MM-DD');
			break;

		case TIME_GRID_4_DAYS:
			start = date;
			end = dayjs(date).add(3, 'day').format('YYYY-MM-DD');
			break;

		default:
			break;
	}

	return { start, end };
};

const getDateRangeFetchJob = (mode, date = today) => {
	let start, end;

	switch (mode) {
		case TIME_GRID_DAY:
			start = dayjs(date).subtract(1, 'day').format('YYYY-MM-DD');
			end = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
			break;

		case TIME_GRID_WEEK:
			start = dayjs(date).subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
			end = dayjs(date).add(1, 'week').endOf('week').format('YYYY-MM-DD');
			break;

		case DAY_GRID_MONTH:
			start = dayjs(date).startOf('month').startOf('week').format('YYYY-MM-DD');
			end = dayjs(start, 'YYYY-MM-DD').add(5, 'week').endOf('week').format('YYYY-MM-DD');
			break;

		case TIME_GRID_3_DAYS:
			start = dayjs(date).subtract(3, 'day').format('YYYY-MM-DD');
			end = dayjs(date).add(5, 'day').format('YYYY-MM-DD');
			break;

		case TIME_GRID_4_DAYS:
			start = dayjs(date).subtract(4, 'day').format('YYYY-MM-DD');
			end = dayjs(date).add(7, 'day').format('YYYY-MM-DD');
			break;

		default:
			break;
	}

	return { start, end };
};

export {
	getInitDate,
	get4DaysRolling,
	get3DaysRolling,
	get3Weeks,
	getWeek,
	getDaysArray,
	getDayObj,
	getDateRange,
	getDateRangeFetchJob,
};
