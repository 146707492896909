import { SET_IS_DISABLE_SWIPE } from 'core/redux/actions/ActionTypes';

const INIT_STATE = {
	isDisable: false,
};

const SwipeStateReducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SET_IS_DISABLE_SWIPE:
			return {
				...state,
				isDisable: action.isDisable,
			};

		default:
			return state;
	}
};

export default SwipeStateReducer;
