import React from 'react';
import SocketIO from 'socket.io-client';

import { REALTIME_ENDPOINT } from 'configs/environments';
import { OPTIONS, SOCKET_NAMESPACES } from 'app/constants/Realtime';

const socket = SocketIO(REALTIME_ENDPOINT + SOCKET_NAMESPACES.ADDON, OPTIONS);

const AddonSocketContext = React.createContext();

export { socket, AddonSocketContext };
