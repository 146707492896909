export const LOGIN_INFO = '/auth/loginInfo';
export const JOB_LIST = '/job/loadjobs';
export const COUNT_JOB = '/job/countjobs';

// RESIZE
export const RESIZE_JOB = '/job/resize';
export const RESIZE_TIME_OFF = '/timeoff/resize';

// DRAG
export const MOVE_JOB = '/job/move';
export const MOVE_TIME_OFF = '/timeoff/move';
