import { FETCH_USER_PROFILE, SET_ACCESS_TOKEN } from 'core/redux/actions/ActionTypes';

export const fetchUserProfile = (token, resolve = () => {}, reject = () => {}) => {
	return {
		type: FETCH_USER_PROFILE,
		token,
		resolve,
		reject,
	};
};

export const setAccessToken = token => {
	return {
		type: SET_ACCESS_TOKEN,
		token,
	};
};
