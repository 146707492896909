import { combineReducers } from 'redux';

import AccessTokenReducer from 'core/redux/reducers/profile/AccessTokenReducer';
import UserProfileReducer from 'core/redux/reducers/profile/UserProfileReducer';
import SwipeStateReducer from 'core/redux/reducers/calendar/SwipeStateReducer';
import IsRefreshAllCalendarReducer from 'core/redux/reducers/calendar/IsRefreshAllCalendarReducer';

export default combineReducers({
	userProfile: UserProfileReducer,
	swipeStateCalendar: SwipeStateReducer,
	isRefreshAllCalendar: IsRefreshAllCalendarReducer,
	accessToken: AccessTokenReducer,
});
