import { call, takeLeading, takeLatest, race, take, select } from 'redux-saga/effects';

import FetchAPI from 'core/utils/FetchAPI';

import {
	FETCH_JOB_LIST,
	FETCH_COUNT_JOB,
	FETCH_MOVE_EVENT,
	FETCH_RESIZE_EVENT,
	STOP_FETCH_MOVE_EVENT,
	STOP_FETCH_RESIZE_EVENT,
} from 'core/redux/actions/ActionTypes';

import { JOB_LIST, COUNT_JOB, MOVE_JOB, RESIZE_JOB } from 'app/constants/Api';
import { MOVE, RESIZE, EVENTS, TIMEOFFS } from 'app/constants/ApiV2';

import { TIME_OFF, EVENT, HEADER_API_V1_VERSION, HEADER_API_V2_VERSION, UPDATE_JOB_FAILED } from 'app/constants/App';
import { API_V2_DOMAIN } from 'configs/environments';

function* fetchJobList(action) {
	try {
		const results = yield call(FetchAPI.getFetch, JOB_LIST, action.params, action.token, {
			'api-version': HEADER_API_V1_VERSION,
		});
		if (results?.success) {
			action.onSuccess(results?.events || []);
		} else {
			action.onFailed(results?.message || '');
		}
	} catch (error) {
		action.onFailed(error?.message || '');
	}
}

function* fetchCountJob(action) {
	try {
		const results = yield call(FetchAPI.getFetch, COUNT_JOB, action.params, action.token);
		if (results?.success) {
			action.onSuccess(results?.days || {});
		} else {
			action.onFailed(results?.message || '');
		}
	} catch (error) {
		action.onFailed(error?.message || '');
	}
}

function* fetchResizeEvent(action) {
	const token = yield select(store => store.accessToken);
	const typeEvent = action.typeEvent;
	const id = action.id;
	const params = action.params;
	try {
		let response;
		if (typeEvent !== TIME_OFF && typeEvent !== EVENT)
			response = yield call(FetchAPI.postFetch, RESIZE_JOB, params, token, {
				'api-version': HEADER_API_V2_VERSION,
			});
		else {
			const url = `${typeEvent === TIME_OFF ? TIMEOFFS : EVENTS}/${id?.replace('timeoff_', '')}/${RESIZE}`;
			response = yield call(FetchAPI.putFetch, url, params, token, {}, { baseURL: API_V2_DOMAIN });
		}

		if (response?.success) {
			action.onSuccess(id, params.all, typeEvent);
		} else {
			action.onFailed(id, response?.message || UPDATE_JOB_FAILED);
		}
	} catch (error) {
		action.onFailed(id, error?.message || UPDATE_JOB_FAILED);
	}
}

function* fetchMoveEvent(action) {
	const token = yield select(store => store.accessToken);
	const typeEvent = action.typeEvent;
	const id = action.id;
	const params = action.params;
	try {
		let response;
		if (typeEvent !== TIME_OFF && typeEvent !== EVENT)
			response = yield call(FetchAPI.postFetch, MOVE_JOB, params, token, {
				'api-version': HEADER_API_V2_VERSION,
			});
		else {
			const url = `${typeEvent === TIME_OFF ? TIMEOFFS : EVENTS}/${id?.replace('timeoff_', '')}/${MOVE}`;
			response = yield call(FetchAPI.putFetch, url, params, token, {}, { baseURL: API_V2_DOMAIN });
		}

		if (response?.success) {
			action.onSuccess(id, params.all, typeEvent);
		} else {
			action.onFailed(id, response?.message || UPDATE_JOB_FAILED);
		}
	} catch (error) {
		action.onFailed(id, error?.message || UPDATE_JOB_FAILED);
	}
}

export function* watchFetchJobList() {
	yield takeLatest(FETCH_JOB_LIST, fetchJobList);
}

export function* watchFetchCountJob() {
	yield takeLeading(FETCH_COUNT_JOB, fetchCountJob);
}

export function* watchFetchResizeEvent() {
	yield takeLeading(FETCH_RESIZE_EVENT, function* (...args) {
		yield race({
			task: call(fetchResizeEvent, ...args),
			cancel: take(STOP_FETCH_RESIZE_EVENT),
		});
	});
}

export function* watchFetchMoveEvent() {
	yield takeLeading(FETCH_MOVE_EVENT, function* (...args) {
		yield race({
			task: call(fetchMoveEvent, ...args),
			cancel: take(STOP_FETCH_MOVE_EVENT),
		});
	});
}
