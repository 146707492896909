import React from 'react';

const RecurringIcon = (
	<svg
		version='1.1'
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		x='0px'
		y='0px'
		viewBox='0 0 1000 1000'
		enableBackground='new 0 0 1000 1000'
		xmlSpace='preserve'>
		<metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
		<g>
			<g>
				<path d='M802.7,196.4l-86.4,86.7C774.2,341,806.3,418,806.3,500c0,168.9-137.3,306.3-306.3,306.3V745L377.5,867.5L500,990v-61.3c236.4,0,428.8-192.4,428.8-428.8C928.8,385.3,884,277.5,802.7,196.4z M622.5,132.5L500,10v61.3C263.6,71.3,71.3,263.6,71.3,500c0,114.7,44.7,222.5,126.1,303.6l86.4-86.7C225.8,659,193.8,582,193.8,500c0-168.9,137.3-306.3,306.3-306.3V255L622.5,132.5z' />
			</g>
		</g>
	</svg>
);
export default RecurringIcon;
