import React from 'react';

const WarningIcon = (
	<div
		className='triangle-up'
		style={{ top: '7px' }}>
		<span className='triangle-content'>!</span>
	</div>
);
export default WarningIcon;
