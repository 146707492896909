export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_USER_PROFILE_SUCCESSED = 'FETCH_USER_PROFILE_SUCCESSED';
export const FETCH_USER_PROFILE_FAILED = 'FETCH_USER_PROFILE_FAILED';

export const FETCH_JOB_LIST = 'FETCH_JOB_LIST';

export const FETCH_COUNT_JOB = 'FETCH_COUNT_JOB';

// calendar
export const SET_IS_DISABLE_SWIPE = 'SET_IS_DISABLE_SWIPE';

// handle drag/resize
export const FETCH_RESIZE_EVENT = 'FETCH_RESIZE_EVENT';
export const STOP_FETCH_RESIZE_EVENT = 'STOP_FETCH_RESIZE_EVENT';
export const FETCH_MOVE_EVENT = 'FETCH_MOVE_EVENT';
export const STOP_FETCH_MOVE_EVENT = 'STOP_FETCH_MOVE_EVENT';

export const REFRESH_ALL_CALENDAR = 'REFRESH_ALL_CALENDAR';
