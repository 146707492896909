export const isObject = a => a instanceof Object;

export const isEmpty = obj => {
	// null and undefined are "empty"
	if (obj == null) return true;

	const object_keys = Object.keys(obj);

	// assume if it has a length of property > 0
	if (object_keys.length > 0) return false;
	if (object_keys.length && object_keys.length === 0) return true;

	// if it isn't an object
	if (typeof obj !== 'object') return true;

	return true;
};
