import { ADMIN } from 'app/constants/App';

const isCheckPermission = (permission, permissionsList, unPermissionsList) => {
	if (Array.isArray(permissionsList)) {
		if (checkUserIsAdmin(permissionsList)) {
			if (Array.isArray(unPermissionsList)) {
				const isDenied = unPermissionsList.filter(value => value === permission);
				if (isDenied.length > 0) return false;
				else return true;
			} else return true;
		} else {
			const isAllow = permissionsList.filter(value => value === permission);
			if (isAllow.length > 0) return true;
			else return false;
		}
	} else return false;
};

const checkUserIsAdmin = permissions => {
	if (Array.isArray(permissions)) {
		const adminList = permissions.filter(value => value === ADMIN);
		if (adminList.length > 0) return true;
		else return false;
	} else return false;
};

export { checkUserIsAdmin, isCheckPermission };
