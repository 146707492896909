import { REFRESH_ALL_CALENDAR } from 'core/redux/actions/ActionTypes';

const INIT_STATE = false;

const IsRefreshAllCalendarReducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case REFRESH_ALL_CALENDAR:
			return !state;

		default:
			return state;
	}
};

export default IsRefreshAllCalendarReducer;
