import { SET_ACCESS_TOKEN } from 'core/redux/actions/ActionTypes';

const INIT_TOKEN = '';

const AccessTokenReducer = (token = INIT_TOKEN, action) => {
	switch (action.type) {
		case SET_ACCESS_TOKEN:
			return action.token;

		default:
			return token;
	}
};

export default AccessTokenReducer;
